import React from "react";
import { Link } from "react-router-dom";
import './Header.css'

const Header = () => (
  <header>
    <br />
    <nav>
      <ul className="header-ul">
        <li>
          <Link to="/" className="header-link">Home</Link>
        </li>
        <li>
          <Link to="/passwordgen" className="header-link">PasswordGen</Link>
        </li>
        <li>
          <Link to="/fetchapicall" className="header-link">FetchAPICall</Link>
        </li>
        <li>
          <Link to="/about" className="header-link">About</Link>
        </li>
        <li>
          <Link to="/theme" className="header-link">Theme</Link>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
