import React, { Component } from 'react';


class PasswordGenForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      input: 0,
      result: '12345678' 
    };
  }

  mySubmitHandler = (event) => {
    event.preventDefault();
    window.analytics.track('Password Generate Button Clicked');

    let input = this.state.input
    if (!Number(input)) {
      alert('Password length must be an integer!')
      return
    }

    if (Number(input) > 20) {
      alert('Password too long!')
      return
    }

    var result = '';
    var array = Array(Number(input));
    var i;
    var type;
    for (i of array) {
      type = parseInt(Math.random() * 5, 10)
      switch(type) {        
        case 0: // number
          result = result + String.fromCharCode(48 + parseInt(Math.random() * 10, 10));
          break;
        case 1: // upper case
          result = result + String.fromCharCode(65 + parseInt(Math.random() * 26, 10));
          break;
        default: // lower case
          result = result + String.fromCharCode(97 + parseInt(Math.random() * 26, 10));
      }      
    }
    this.setState({result: result});
  }

  myChangeHandler = (event) => {
    this.setState({input: event.target.value});
  }

  render() {
    return (
      <div>
        <form onSubmit={this.mySubmitHandler}>
          Password Length: <input type='text' onChange={this.myChangeHandler}/>
          <br/><button>Generate</button>
        </form>
        <p>Input: {this.state.input}</p>
        <p>Result: {this.state.result}</p>
      </div>
    );
  }
}

export default class PasswordGen extends Component {
 componentDidMount() {
   window.analytics.page('PasswordGen');
 }

  render() {
    return (
      <div>

      	<br />
      	<br />
        <PasswordGenForm />
      	<br />
      	<br />

      </div>
    );
  }
}