import React, { Component } from 'react';
import logo from './logo.svg';
import cmile from './cmile.svg';

export default class Home extends Component {
 componentDidMount() {
   window.analytics.page('Home');
 }

  render() {
    return (
      <div>

      	<br />
      	<br />
      	<br />
        <img src={cmile} className="App-logo" alt="logo" />
      	<br />
      	<br />

        <p>
          Welcome to cmile.net          
        </p>

      </div>
    );
  }
}