import React, { Component } from 'react';
import cmile from './cmile.svg';
import './About.css';

export default class About extends Component {
 componentDidMount() {
   window.analytics.page('About');
 }

  trackEvent() {
    window.analytics.track('React Link Clicked');
  }

  render() {
    return (
      <div>
        <br/>
        <br/>

        <br/>
        <br/>

        <p>
          © 2020 cmile.net
        </p>
        
        <a
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
          className="header-link"
          onClick={this.trackEvent}
        >
        Created by React
        </a>
      </div>
    );
  }
}