import { Switch, Route } from 'react-router-dom';

import Home from './Home';
import About from './About';
import PasswordGen from './PasswordGen';
import FetchAPICall from './FetchAPICall';
import ThemeState from './ThemeState';
import Header from './Header';

import './App.css';

function App() {
  return (
    <div className="App">
      <Header />

      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/passwordgen" component={PasswordGen} />
        <Route path="/fetchapicall" component={FetchAPICall} />
        <Route path="/theme" component={ThemeState} />
      </Switch>

    </div>
  );
}

export default App;
