import React, { useState } from 'react';

function ThemeState() {
    const [theme, setTheme] = useState("red")

    const onClickHander = () => {
        setTheme(theme === "red" ? "blue" : "red")
    }

    return (
        <div>
            <Text theme={theme} />
            <button className='btn btn-primary' onClick={onClickHander}>Change theme</button>
        </div>
    )
}

function Text({ theme }) {
    return (
        <h1 style={{ color: `${theme}` }}>{theme}</h1>
    )
}

export default ThemeState;