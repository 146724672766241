import React, { Component } from 'react';


export default class FetchAPICall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      result: null,
      error: null
    };
  }


  componentDidMount() {
    window.analytics.page('FetchAPICall');
    this.fetchResult();
  }

  fetchResult() {
    // fetch('http://localhost:5003/check')
    fetch('https://cmile.net/user/check')
      .then(response => response.json())
      .then(data =>
        this.setState({
          result: data,
          isLoading: false
        })
        )
      .catch(error => this.setState({ error, isLoading: false}))

    // fetch call is async, so isLoading = true
    console.log( 'isLoading:', this.state.isLoading )
    console.log( 'has output:', this.state.result )
  }

  render() {
    const {isLoading, result, error} = this.state

    return(
      <React.Fragment>
        <h1>Result</h1>

        {error ? <p> {error.message} </p> : null}

        {!isLoading 
          ? ( result.output ) 
          : ( <h3>Loading...</h3> )
        }
      </React.Fragment>
    )
  }
}